/* eslint-disable @typescript-eslint/no-type-alias */

export enum FranchiseeIdActions {
  SET_ID = 'SET_ID',
  UNSET_ID = 'UNSET_ID',
  FAILURE = 'FAILURE'
}

interface ISetFranchiseeIdAction {
  type: FranchiseeIdActions.SET_ID;
  payload: string;
}

interface IUnSetFranchiseeIdAction {
  type: FranchiseeIdActions.UNSET_ID;
}

interface IFailureFranchiseeIdAction {
  type: FranchiseeIdActions.FAILURE;
}

export type FranchiseeActionTypes =
  | ISetFranchiseeIdAction
  | IUnSetFranchiseeIdAction
  | IFailureFranchiseeIdAction;
