export interface IFranchiseeId {
  franchiseeId?: string;
}

export const KEY = '@lce/local/franchiseeId';

export interface IDatoFranchiseeNodes {
  nodes: IDatoFranchisee[];
}

export interface IDatoFranchisee {
  customTheme: string;
  originalId: string;
  isactive: boolean;
  isRtl: boolean;
  locale: string;
}
