import * as React from 'react';

import { IFranchiseeId } from '../types';
import { FranchiseeActionTypes } from './Action';

interface IFranchiseeIdContext {
  state: IFranchiseeId;
  dispatch: React.Dispatch<FranchiseeActionTypes>;
}

export const FranchiseeIdContext = React.createContext<IFranchiseeIdContext | undefined>(undefined);
