import { graphql, useStaticQuery } from 'gatsby';
import { map as _map, filter as _filter } from 'lodash';
import { localeFilterList } from '@lce/intl-util/src/localeFilter';
import { ILanguage } from '@lce/intl-ui/src/Patterns/Footer/components/LanguageSelector';

import { IStartYourOrder } from '../../../ui/Patterns/StartYourOrder/StartYourOrder';

interface IDatoUseFranchisees {
  franchisees: IDatoFranchiseesNodes;
}

interface IDatoFranchiseesNodes {
  nodes: IDatoFranchisees[];
}

interface IDatoFranchisees {
  locale: string;
  name: string;
  originalId: string;
  isactive: boolean;
  isRtl: boolean;
  customTheme: string;
  languages: ILanguage[];
  startYourOrder: IStartYourOrder;
}

export interface IFranchiseesList {
  franchisees: IFranchisees[];
}

export interface IFranchisees {
  name: string;
  id: string;
  isActive: boolean;
  isRtl: boolean;
  customTheme: string;
  languages: ILanguage[];
  startYourOrder: IStartYourOrder;
}

// eslint-disable-next-line max-lines-per-function
const useFranchisees = (locale: string): IFranchiseesList => {
  const data: IDatoUseFranchisees = useStaticQuery(graphql`
    query UseFranchiseesMsQuery {
      franchisees: allDatoCmsFranchisee {
        nodes {
          name
          customTheme
          originalId
          locale
          isRtl
          isactive
          languages {
            locale
            cultureCode
            languageDisplayName
          }
          startYourOrder {
            locale
            startYourOrderText
            pickupButtonText
            pickupButtonUrl
            deliveryButtonText
            deliveryUrl
          }
        }
      }
    }
  `);

  
  const filteredFranchisees = localeFilterList<IDatoFranchisees>(data?.franchisees?.nodes, locale);

  const activeFranchisees = _filter(filteredFranchisees, (node: any) => node.isactive === true);
  
  const cleanedFranchisees: IFranchisees[] = _map(activeFranchisees, (franchisee) => ({
    name: franchisee.name,
    id: franchisee.originalId,
    isActive: franchisee.isactive,
    isRtl: franchisee.isRtl,
    customTheme: franchisee.customTheme,
    languages: franchisee.languages,
    startYourOrder: franchisee.startYourOrder,
  }));

  return { franchisees: cleanedFranchisees };

};

export default useFranchisees;
