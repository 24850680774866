import * as React from 'react';

import { IFranchiseeId } from '../types';
import { getFranchiseeId, setFranchiseeId, initializeFranchiseeId  } from '../utilities';

import { initialState } from './initialState';

import { FranchiseeIdActions, FranchiseeActionTypes } from './Action';
import { FranchiseeIdContext } from './FranchiseeIdContext';

const franchiseeIdReducer = (state: IFranchiseeId, action: FranchiseeActionTypes): IFranchiseeId => {
  switch (action.type) {
    case FranchiseeIdActions.SET_ID:
      setFranchiseeId({ ...state, franchiseeId: action.payload });
      return { ...state, franchiseeId: action.payload };
    case FranchiseeIdActions.UNSET_ID:
      setFranchiseeId({ ...state, franchiseeId: undefined });
      return { ...state, franchiseeId: undefined };
    default:
      throw new Error('Unsupported action type');
  }
};

export const FranchiseeIdProvider: React.FC = (props) => {
  initializeFranchiseeId();

  const selectedFranchiseeId = getFranchiseeId();

  const [ state, dispatch ] = React.useReducer(franchiseeIdReducer, { franchiseeId: selectedFranchiseeId } || initialState);

  const value = React.useMemo(() => ({ state, dispatch }), [ state ]);
  return <FranchiseeIdContext.Provider value={ value } { ...props } />;
};

export default FranchiseeIdProvider;
