import * as React from 'react';
import { find as _find } from 'lodash';

import { FranchiseeIdContext } from '../context';
import { IFranchiseeId } from '../types';
import { initialState } from '../context/initialState';
import useFranchisees from './useFranchisees';
import { useFranchiseeSelectorConfig } from './useFranchiseeSelectorConfig';

export const useSelectedFranchiseeId = (locale: string): IFranchiseeId => {
  // tslint:disable-next-line
  if (typeof window === 'undefined') {
    return initialState;
  }
  const context = React.useContext(FranchiseeIdContext);
  const { isFranchiseeSelectorEnabled } = useFranchiseeSelectorConfig();

  if (!context) {
    // eslint-disable-next-line no-console
    console.log('useSelectedFranchiseeId must be used within an FranchiseeIdProvider');
    return initialState;
  }

  const { state } = context;

  const { franchisees } = useFranchisees(locale);
  const isRedirect = !state.franchiseeId;
  const isNotRoot = locale && window.location.pathname !== `/${locale.toLocaleLowerCase()}/`;
  const isStore = _find(franchisees, (franchisee) => {
    const franchiseeName = franchisee.name.toLocaleLowerCase().replace(/\s+/g, '-');
    return window.location.pathname.toLocaleLowerCase().includes(franchiseeName);
  });
  const isValidFranchiseeId = _find(franchisees, (franchisee) => state.franchiseeId && franchisee.id === state.franchiseeId);
  if (!isFranchiseeSelectorEnabled) return state;

  if(isRedirect && isNotRoot && !isStore || !isValidFranchiseeId && isNotRoot && !isStore) {
    window.location.assign(`/${locale.toLocaleLowerCase()}/`);
    return {};
  }

  if(!isValidFranchiseeId) {
    return {};
  }

  return state;
};
