/* eslint-disable react/jsx-filename-extension */
import * as React from 'react';

import FranchiseeIdProvider from './src/api/FranchiseeId/context/FranchiseeIdProvider';

export const wrapRootElement = ({ element }) => (
  <FranchiseeIdProvider>
    { element}
  </FranchiseeIdProvider>
);
