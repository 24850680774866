import * as React from 'react';

import { FranchiseeIdContext, FranchiseeIdActions } from '../context';

export const useUpdateFranchiseeId = () => {
  const context = React.useContext(FranchiseeIdContext);

  if (!context) {
    // eslint-disable-next-line no-console
    console.log('useUpdateFranchiseeId must be used within an FranchiseeIdProvider');
    
    // eslint-disable-next-line no-empty-function
    const mockFunction = () => {  };

    return [ mockFunction, mockFunction ] as unknown as [ (franchiseeId: string) => void, () => void ];
  }

  const { dispatch } = context;

  const setFranchiseeId = (franchiseeId: string): void => {
    dispatch({ type: FranchiseeIdActions.SET_ID, payload: franchiseeId });
  };

  const unsetFranchiseeId = (): void => {
    dispatch({ type: FranchiseeIdActions.UNSET_ID });
  };

  return [ setFranchiseeId, unsetFranchiseeId ] as [ (franchiseeId: string) => void, () => void ];
};
