/* eslint-disable babel/no-unused-expressions */
import stringConversion from '@lce/intl-util/src/stringConversion';

import { IFranchiseeId, KEY } from '../types';
import { initialState } from '../context/initialState';
import { useFranchiseeSelectorConfig } from '../hooks'

export const setFranchiseeId = (franchiseeId: IFranchiseeId): void => {
  window && window.localStorage && window.localStorage.setItem(KEY, stringConversion<IFranchiseeId>(franchiseeId));
};

export const getFranchiseeId = (): string | undefined => {
  // tslint:disable-next-line
  if (typeof window === 'undefined') {
    return undefined;
  }

  const franchiseeId = stringConversion(window.localStorage.getItem(KEY)) as IFranchiseeId;

  return franchiseeId?.franchiseeId;
};

export const removeFranchiseeId = (): void => {
  window && window.localStorage && window.localStorage.removeItem(KEY);
};
export interface ISiteConfigProps {
  siteConfig: {
    nodes: {isFranchiseeSelectorEnabled: boolean}[];
  };
}

export const initializeFranchiseeId = (): void => {
  const { isFranchiseeSelectorEnabled } = useFranchiseeSelectorConfig();
  const isLocalStorageAvailable = window && window.localStorage;

  if (isFranchiseeSelectorEnabled) {
    isLocalStorageAvailable && !window.localStorage.getItem(KEY) && setFranchiseeId(initialState);
  } else {
    isLocalStorageAvailable && setFranchiseeId(initialState);
  }
};
